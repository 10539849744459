<template>
  <div>
    <b-card>
      <v-table
        ref="mailing-table"
        :fields="mailingTable"
        path="mailing"
        table-sm
        @create="onCreateHandler"
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(date_start)="data">
          <a
            href="javascript: null"
            @click="$router.push({name: 'mailing-item-list', params: { id: data.item.id }})"
          >
            {{ data.value }}
          </a>
        </template>
        <template #cell(count_items)="{item}">
          <b-progress :max="item.count_items" show-value>
            <b-progress-bar :value="item.count_sent" variant="success"/>
            <b-progress-bar :value="item.count_errors" variant="danger"/>
          </b-progress>
        </template>
      </v-table>
    </b-card>
    <b-modal
      ref="create-modal"
      title="Рассылка агентских отчетов"
      hide-footer
    >
      <mailing-form
        @created="onMailingCreatedHandler"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import VTable, { VTableSerialColumn } from '@/views/components/vtable'
import MailingForm from '@/views/mailing/components/MailingForm.vue'
import { mailingTable } from './mailingUse'

export default {
  components: {
    MailingForm,
    VTable,
    VTableSerialColumn,
    BCard,
    BModal,
    BProgress,
    BProgressBar,
  },
  data() {
    return {
      mailingTable,
    }
  },
  computed: {
  },
  methods: {
    onCreateHandler() {
      this.$refs['create-modal'].show()
    },
    onMailingCreatedHandler() {
      this.$refs['create-modal'].hide()
      this.$refs['mailing-table'].refresh()
    },
  },
}
</script>
