<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="step === 1"
        key="1"
      >
        <b-form-group
          label="Период"
        >
          <div class="row">
            <div class="col-6">
              <b-form-input
                v-model="formData.date_start"
                type="date"
              />
            </div>
            <div class="col-6">
              <b-form-input
                v-model="formData.date_end"
                type="date"
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group
          label="Тема письма"
        >
          <b-form-input
            v-model="formData.subject"
          />
        </b-form-group>
        <b-form-group
          label="Тело письма"
        >
          <b-form-textarea
            v-model="formData.body"
          />
        </b-form-group>
        <b-form-group
          label="Обратный Email"
        >
          <b-form-input
            ref="email-input"
            v-model="formData.email_from"
            type="email"
          />
        </b-form-group>
        <div
          v-for="(message, idx) in step1Errors"
          :key="idx"
          class="alert alert-danger p-1"
        >{{ message }}
        </div>
        <div
          class="btn btn-primary btn-block"
          @click="onNextStepHandler"
        >Далее
        </div>
      </div>
      <div
        v-if="step === 2"
        key="2"
      >
        <b-overlay :show="itemsLoading">
          <div>
            <b-form-checkbox
              @change="onAllChangeHandler"
            >
              Все
            </b-form-checkbox>
          </div>
          <hr/>
          <div
            class="items-list"
          >
            <div
              v-for="item in items"
              :key="item.driver_id"
              class="d-flex justify-content-between"
            >
              <b-form-checkbox v-model="item.selected">{{ item.driver | displayDriver }}</b-form-checkbox>
              <span>
                <a
                  href="javascript: null"
                  @click="onAmountClickHandler(item)"
                >{{ item.amount }}</a>
              </span>
            </div>
          </div>
          <hr/>
          <div class="btn-group w-100">
            <button
              class="btn btn-outline-primary"
              @click="onPrevStepHandler"
            >
              Назад
            </button>
            <button
              class="btn btn-primary"
              :disabled="!selectedItems.length"
              @click="onCreateClickHandler"
            >
              Создать
            </button>
          </div>
        </b-overlay>
      </div>
    </transition>
  </div>
</template>

<script>
import { BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BOverlay } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      step: 1,
      formData: {
        date_start: null,
        date_end: null,
        subject: null,
        body: null,
        email_from: null,
      },
      items: [],
      step1Errors: [],
      step2Errors: [],
      itemsLoading: false,
    }
  },
  computed: {
    selectedItems() {
      return this.items.filter(item => item.selected)
    },
  },
  mounted() {
    this.setDefaultDates()
  },
  methods: {
    setDefaultDates() {
      const now = moment()
        .subtract(1, 'month')
      this.formData.date_start = moment()
        .subtract(3, 'month')
        .format('YYYY-MM-01')
      this.formData.date_end = now.format(`YYYY-MM-${now.daysInMonth()}`)
    },
    fetchItems() {
      this.itemsLoading = true
      this.$axios.get('mailing/items/available', {
        params: {
          filter: {
            date__gte: this.formData.date_start,
            date__lte: this.formData.date_end,
          },
        },
        timeout: 60_000,
      })
        .then(response => {
          this.items = response.data.data.map(item => ({
            ...item,
            selected: false,
          }))
        })
        .finally(() => {
          this.itemsLoading = false
        })
    },
    onNextStepHandler() {
      this.step1Errors = []
      if (!this.formData.date_start || !this.formData.date_end || !this.formData.subject || !this.formData.body || !this.formData.email_from) {
        this.step1Errors.push('Заполните все поля')
        return
      }
      this.step = 2
      this.fetchItems()
    },
    onPrevStepHandler() {
      this.step = 1
    },
    onAllChangeHandler(value) {
      this.items = this.items.map(i => ({
        ...i,
        selected: value,
      }))
    },
    onCreateClickHandler() {
      if (this.selectedItems) {
        this.$axios.post('mailing', {
          ...this.formData,
          items: this.selectedItems.map(item => ({
            amount: item.amount,
            driver_id: item.driver_id,
          })),
        }).then(() => {
          this.$emit('created')
        })
      }
    },
    onAmountClickHandler(item) {
      this.$axios.post('/print/mailing-item', {
        driver_id: item.driver_id,
        date_start: this.formData.date_start,
        date_end: this.formData.date_end,
      }, { responseType: 'blob' })
    },
  },
}
</script>
<style scoped>
.items-list {
  min-height: 350px;
  max-height: 600px;
  overflow: auto;
}
</style>
